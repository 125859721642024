.wrapper {
    position: relative;
    margin: 0 auto;
}

.imageWrapper {
    margin-bottom: 32px;
}
  
.borderImage {
    position: absolute;
    left: -29px;
    top: -32px;
    pointer-events: none;
}

.infoWrapper {
    min-height: 80px;
}

/* Label component */
.infoWrapper > div:first-child{
    min-height: 40px;
    max-width: 400px;
    margin: 0 auto;
}

.injectionSiteWrapper {
    margin-top: 5px;
}
  

.injectionSiteCaption {
    font-family: var(--font-sans);
    font-size: 16px;
    max-width: 400px;
    margin: 0 auto;
}

.injectionSiteCaption label {
    color: var(--color-yellow);
}

/* Tools and help for retrograde selection */
.tools {
    position: absolute;
    bottom: 180px;
    left: 0;
    width: 160px;
    font-family: var(--font-sans);
    font-size: 13px;
    line-height: 18px;
    text-align: left;
    z-index: 500;
}

.toolsHelp,
.clearSelection {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    gap: 5px;
}

.toolsHelp {
    margin-bottom: 15px;
    color: var(--color-green);
}

.clearSelection {
    font-weight: bold;
    text-transform: uppercase;    
}

.clearSelection:hover {
    text-decoration: underline;
    cursor: pointer;
}

.clearSelection div {
    height: 36px;
}