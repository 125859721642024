.wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    column-gap: 20px;
    padding: 40px 44px 80px 44px;
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.column:first-child {
    flex: 5;
}

.column:last-child {
    flex: 1;
}