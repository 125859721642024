.wrapper {
  width: 60%;
  margin: 0 auto;
  padding: 70px;
  background-color: #373E46;
  margin-top: 220px;
}

.wrapper * {
  font-family: var(--font-sans);
  text-align: left;
}

.wrapper h2 {
  font-size: 32px;
  line-height: 40px;
  margin-top: 0;
  margin-bottom: 30px;
  color: var(--color-yellow);
  font-family: var(--font-mono);
}

.wrapper p {
  font-size: 19px;
  line-height: 27px;
  margin-bottom: 15px;
}

.wrapper a {
  text-decoration: none;
  color: var(--color-green);
}

.wrapper a:hover {
  text-decoration: underline;
}

.orange {
  color: var(--color-orange);
}

.blue {
  color: var(--color-blue);
}