.pageWrapper {
  background-color: #1f2429;
  padding: 0 40px;
  background-repeat: no-repeat;
}

.wrapper {
  max-width: 1000px;
  margin: 0 auto;
  padding-bottom: 40px;
}

.wrapper h1 {
  font-size: 55px;
  font-family: var(--font-mono);
  background-color: var(--color-yellow);
  margin-top: 0;
  padding: 230px 0 60px;
  color: black;
}

.wrapper h2 {
  font-family: var(--font-mono);
  font-size: 38px;
  line-height: 46px;
  color: var(--color-yellow);
  text-align: left;
  margin-top: 60px;
  margin-bottom: 20px;
}

.wrapper p {
  font-family: var(--font-sans);
  font-size: 20px;
  line-height: 32px;
  text-align: left;
}

.wrapper a {
  background-color: var(--color-green);
  color: black;
  text-decoration: none;
  word-break: break-all;
  padding: 0 10px;
}

.wrapper a:hover {
  background-color: var(--color-yellow);
  color: black;
}