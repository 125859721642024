.App {
  text-align: center;
  /* background-color: black; */
  background: linear-gradient(.25turn, #292e35, #0b0e0c 50%, #292e35 50%, #0b0e0c);
  color: white;
  height: 100vh;
}

.headerWrapper {
  height: 30px;
}

.outletWrapper {
  height: calc(100% - 30px);
  position: relative;
}

@media screen and (max-width: 1024px) {
  .outletWrapper {
    display: none;
  }
}