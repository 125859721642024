.wrapper {
  position: fixed;
  top: 30px;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: black;
  z-index: 9999;
  pointer-events: none;
}

.wrapper p {
  color: var(--color-yellow);
  font-family: var(--font-mono);
  font-size: 24px;
  text-align: center;
  max-width: 75%;
  margin: 0 auto;
}

.wrapper img {
  margin-top: 100px;
  margin-bottom: 40px;
}

@media screen and (min-width: 500px) {
  .wrapper p {
    font-size: 34px;
  }
  .wrapper img {
    margin-top: 140px;
    margin-bottom: 60px;
  }
}

@media screen and (min-width: 1025px) {
  .wrapper {
    display: none;
    position: relative;
  }
}