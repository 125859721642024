.wrapper {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    padding: 40px 44px 80px 44px;
    height: calc(100% - 120px);
}

.column {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    position: relative;
}

.column:first-child {
    flex: 5;
}

.column:last-child {
    flex: 1;
}