@import "../../css/global.css";

.Header {
  background-color: white;
  color: black;
  display: flex;
  padding: 0 34px 0 40px;
  height: 30px;
  flex-direction: row;
  justify-content: space-between;
}

.menu {
  margin: 0;
  display: flex;
  gap: 8px;
  list-style: none;
}

.Header > a {
    display: inline-block;
    margin-top: -3px;
    position: relative;
}

.Header a {
  font-family: var(--font-sans);
  font-weight: bold;
  font-size: 17px;
  line-height: 30px;
  letter-spacing: 0.85px;
  color: #000000;
  text-transform: uppercase;
  text-decoration: none;
  padding: 4px 10px;
}

.Header a.active {
    background-color: var(--color-yellow);
}

.Header a:hover {
    background-color: var(--color-green);
}

.safari {
  background-color: var(--color-yellow);
  height: 30px;
  position: fixed;
  z-index: 9999;
  color: black;
  font-family: var(--font-sans);
  font-weight: bold;
  font-size: 17px;
  line-height: 30px;
  width: 100%;
}

@media screen and (max-width: 1024px) {
  .Header {
    width: 100%;
    padding: 0;
  }
  .Header a {
    width: 100%;
    text-align: center;
    pointer-events: none;
  }
  .menu {
    display: none;
  }
}