
@import "../../css/global.css";

.wrapper {
    padding-right: 20px;
    display: flex;
    margin-bottom: 26px;
}

.wrapper h2 {
    color: var(--color-yellow);
    font-family: var(--font-mono);
    font-size: 32px;
    line-height: 40px;
    text-align: left;
    margin: 0 0 10px;
}

.wrapper.blue h2 {
    color: var(--color-yellow);
}

.wrapper p {
    font-family: var(--font-sans);
    text-align: left;
    font-size: 18px;
    line-height: 26px;
    margin: 0;
    max-width: 600px;
}
