@import "../../css/global.css";

.wrapper {
  width: 100%;
}

.wrapper * {
  font-family: var(--font-sans);
  font-size: 16px;
}

.imageWrapper {
    position: relative;
    width: 240px;
}

.imageWrapper canvas,
.imageWrapper img {
  width: 100%;
}

.controls {
  margin-top: 10px;
  display: flex;
  justify-content: space-between;
}

.inputWrapper {
  border: 1px solid var(--color-border-gray);
  border-radius: 32px;
  height: 28px;
  padding: 2px;
  vertical-align: middle;
  display: flex;
}

.label {
  text-transform: capitalize;
  color: var(--color-yellow);
  font-weight: bold;
  line-height: 34px;
}

.input {
  max-width: 44px;
  margin: 0 3px;
  height: 27px;
  background-color: black;
  color: white;
  text-align: center;
  box-shadow: none;
  padding: 0;
  border: none;
}

.invalid {
  border-color: var(--color-red);
}
.invalid input {
  color: var(--color-red);
}

.controlWrapper {
  height: 27px;
}

.controlWrapper .arrowHover {
  display: none;
}

.controlWrapper:hover .arrowHover {
  display: block;
  cursor: pointer;
}

.controlWrapper:hover .arrow {
  display: none;
}

.marker {
  position: absolute;
  pointer-events: none;
}

.cross:before,
.cross:after {
  content: "";
  position: absolute;
  z-index: 2;
  background-color: var(--color-marker-green);
}

.cross:before {
  top: 1.5px;
  left: 50%;
  width: 3px;
  height: 100%;
}

.cross:after {
  left: 1.5px;
  top: 50%;
  height: 3px;
  width: 100%;
}

.circle {
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: var(--color-marker-green);
}

.left,
.right {
  color: white;
  font-size: 18px;
  font-weight: bold;
  width: 10px;
  position: absolute;
  bottom: 3px;
}

.left {
  left: 5px;
}

.right {
  right: 5px;
}