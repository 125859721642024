@import "../../css/global.css";

.wrapper {
    padding-left: 100px;
    padding-top: 100px;
    height: calc(100% - 100px);
    background-position: bottom right;
    background-repeat: no-repeat;
    background-color: black;
    background-size: 65% auto;
}

.wrapper h1 {
    color: var(--color-yellow);
    font-family: var(--font-mono);
    max-width: 50%;
    text-align: left;
    font-size: 53px;
    line-height: 60px;
    font-weight: bold;
}

.wrapper p {
    font-family: var(--font-sans);
    max-width: 35%;
    text-align: left;
    font-size: 23px;
    line-height: 32px;
}

.wrapper a {
    display: block;
    width: fit-content;
    text-align: left;
    color: var(--color-green);
    border: 3px solid var(--color-green);
    background-color: rgba(0,0,0,.75);
    font-family: var(--font-sans);
    font-size: 30px;
    font-weight: bold;
    text-decoration: none;
    padding: 20px 40px;
    margin-top: 40px;
    transition: all ease .25s;
}

.wrapper a:hover {
    border-color: var(--color-yellow);
    color: var(--color-yellow);
}