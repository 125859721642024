.wrapper {
    min-height: 200px;
}

.topRow {
    display: flex;
    margin-bottom: 40px;
}

.bottomRow {
    display: flex;
    justify-content: space-between;
}

.bottomRowLeft {
    flex: 2;
}

.bottomRowRight {
    flex: 1;
}


.wrapper * {
    font-family: var(--font-sans);
    font-size: 16px;
    text-align: left;
}

.wrapper h3 {
    color: var(--color-yellow);
    margin-top: 5px;
    margin-bottom: 10px;
}

.subjectJ,
.subjectN {
    display: inline-block;
    margin-right: 20px;
    min-width: 130px;
}

.subjectJ::before {
    background-color: var(--color-xbtd22-j);
}

.subjectN::before {
    background-color: var(--color-xbtd22-n);
}

.subjectN::before,
.subjectJ::before {
    content: "";
    height: 12px;
    width: 12px;
    border-radius: 12px;
    margin-right: 5px;
    display: inline-block;
}

.noConnections {
    display: flex;
    flex-direction: row;
}

.noConnectionsDots {
    min-width: 40px;
}
.subjectJDisabled,
.subjectNDisabled {
    height: 12px;
    width: 12px;
    border-radius: 12px;
    margin-right: 5px;
    display: inline-block;
}
.subjectJDisabled {
    background-color: var(--color-xbtd22-j-faded);
}
.subjectNDisabled {
    background-color: var(--color-xbtd22-n-faded);
}