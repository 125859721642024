.point {
  position: absolute;
  pointer-events: none;
  border: 1px solid #21262C;
}

.xbtd22-j {
  background-color: var(--color-xbtd22-j);
}

.xbtd22-n {
  background-color: var(--color-xbtd22-n);
}
