.trigger {
  display: inline-block;
  font-family: var(--font-sans);
  font-size: 11px;
  line-height: 20px;
  font-weight: bold;
  color: black;
  background-color: var(--color-green);
  width: 20px;
  height: 20px;
  border-radius: 50%;
  margin-left: 10px;
}
.trigger:hover {
  cursor: pointer;
  background-color: var(--color-yellow);
}


.wrapper {
  position: fixed;
  z-index: 900;
  width: 100vw;
  height: 100%;
  top: 30px;
  left: 0;
  background-color: #0D0F12;
  padding-top: 45px;
  padding-left: 45px;
}

.overlayHeader {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.close {
  color: var(--color-green);
  font-family: var(--font-sans);
  font-weight: bold;
  font-size: 16px;
  text-transform: uppercase;
}
.close img {
  margin-left: 10px;
}

.close:hover {
  cursor: pointer;
  text-decoration: underline;
}

.overlayHeader,
.overlayBody {
  width: 50vw;
}

.overlayBody {
  margin-top: 50px;
  font-family: var(--font-sans);
  font-size: 19px;
  line-height: 26px;
  color: white;
  text-align: left;
}

.overlayBody div.row {
  margin-bottom: 20px;
  display: flex;
}
div.image {
  display: inline-block;
  width: 35%;  
  text-align: right;
}
div.text {
  display: inline-block;
  width: 65%;
} 
.overlayBody div img {
  position: relative;
  padding-right: 20px;
}

.overlayBody a {
  text-decoration: none;
  color: var(--color-green);
}
.overlayBody a:hover {
  text-decoration: underline;
}

