.wrapper * {
    font-family: var(--font-sans);
    font-size: 16px;
    text-align: left;
}

.wrapper h3 {
    color: var(--color-yellow);
    margin-bottom: 10px;;
}

.connectionRegionLabelWrapper {
    min-height: 38px;
}
