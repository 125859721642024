@import "../../css/global.css";

.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-family: var(--font-sans);
  font-size: 16px;
  text-align: left;
}

.label {
  display: block;
  text-align: left;
  color: var(--color-yellow);
  font-family: var(--font-sans);
  font-weight: bold;
  margin-bottom: 15px;
}

.controlWrapper select {
  color: white;
  background-color: transparent;
  border: 1px solid white;
  padding: 0 30px 0 10px;
  line-height: 34px;
  height: 34px;
  font-size: 16px;
  letter-spacing: 1px;
  appearance: none;
  background-image: url(/src/assets/images/select.svg);
  background-position: center right 10px;
  background-repeat: no-repeat;
}

.controlWrapper select:hover {
  background-image: url(/src/assets/images/selectHover.svg);
}

.controlWrapper select option {
  color: white;
  background-color: black;
}

.rangeLabels {
  font-size: 13px;
  display: flex;
  justify-content: space-between;
}

.rangeLabels span {
  display: inline-block;
}

.controlWrapper input[type="range"] {
  -webkit-appearance: none;
  appearance: none;
  background: transparent;
  cursor: pointer;
  width: 150px;
  height: 24px;
}

/******** Firefox ********/
.controlWrapper input[type="range"]::-moz-range-track {
  background: white;
  width: 100%;
  height: 2px;
}

.controlWrapper input[type="range"]::-moz-range-thumb {
  border: none; /*Removes extra border that FF applies*/
  background-color: var(--color-green);
  height: 20px;
  width: 20px;
  border-radius: 20px;
}

/******** Everybody else ********/
.controlWrapper input[type="range"]::-webkit-slider-runnable-track {
  background: white;
  width: 100%;
  height: 2px;
}

.controlWrapper input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none; /* Override default look */
  appearance: none;
  margin-top: -10px; /* Centers thumb on the track */
  background-color: var(--color-green);
  height: 20px;
  width: 20px;
  border-radius: 20px;
}
