
.wrapper {
  display: flex;
  flex-direction: row;
  gap: 20px;
  font-family: var(--font-sans);
  font-size: 16px;
  text-align: left;
}

.label {
  display: block;
  text-align: left;
  color: var(--color-yellow);
  font-family: var(--font-sans);
  font-weight: bold;
  margin-bottom: 15px;
}

.controlWrapper select {
  color: white;
  background-color: transparent;
  border: 1px solid white;
  padding: 0 30px 0 10px;
  line-height: 34px;
  height: 34px;
  font-size: 16px;
  letter-spacing: 1px;
  appearance: none;
  background-image: url(/src/assets/images/select.svg);
  background-position: center right 10px;
  background-repeat: no-repeat;
}

.controlWrapper select:hover {
  background-image: url(/src/assets/images/selectHover.svg);
}

.controlWrapper select option {
  color: white;
  background-color: black
}
