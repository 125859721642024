:root {
  --fade: 0.3;
  --color-yellow: #fafd76;
  --color-green: #4be59e;
  --color-blue: #44C4FD;
  --color-orange: #FFC558;
  --color-marker-green: #00FF89;
  --color-xbtd22-j: rgb(68, 196, 253);
  --color-xbtd22-j-faded: rgba(68, 196, 253, var(--fade));
  --color-xbtd22-n: rgb(255, 197, 88);
  --color-xbtd22-n-faded: rgb(255, 197, 88, var(--fade));
  --color-border-gray: #707070;
  --color-red: #FF5757;

  --font-sans: 'DM Sans', Arial, Helvetica, sans-serif;
  --font-mono: 'IBM Plex Mono', 'Courier New', Courier, monospace;
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: normal;
  font-display: swap;
  src: url(../assets/fonts/DM-Sans-Regular.woff2) format("woff2");
}

@font-face {
  font-family: "DM Sans";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(../assets/fonts/DM-Sans-700.woff2) format("woff2");
}

@font-face {
  font-family: "IBM Plex Mono";
  font-style: normal;
  font-weight: bold;
  font-display: swap;
  src: url(../assets/fonts/IBM-Plex-Mono-700.woff2) format("woff2");
}
