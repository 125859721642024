.wrapper {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;
}

.wrapper > div { 
  flex: 1;
  background: linear-gradient(.25turn, #292e35, #0b0e0c);
}