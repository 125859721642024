:root {
    --marker-size: 8px;
    --image-width: 100px;
}

.wrapper {
    width: var(--marker-size);
    height: var(--marker-size);
    position: absolute;
    border: 1px solid #21262C;
    border-radius: var(--marker-size);
    cursor: pointer;
}

.xbtd22-j,
.xbtd22-j .hover {
    background-color: var(--color-xbtd22-j);
    outline-color: var(--color-xbtd22-j);
}

.xbtd22-n,
.xbtd22-n .hover
{
    background-color: var(--color-xbtd22-n);
    outline-color: var(--color-xbtd22-n);
}

.hover {
    left: calc(var(--image-width)/-2 + (var(--marker-size)/2));
    top: 30px;
    z-index: 301;
    position: absolute;
    display: none;
    padding: 3px;
}

.hover img {
    width: var(--image-width);
    height: auto;
}

.active,
.selected,
.wrapper:hover {
    outline-style: solid;
    outline-width: 3px;
    outline-offset: 3px;
}

.wrapper:hover {
    z-index: 300;
}

.backgrounded:not(:hover) {
    border-color: rgba(33, 38, 44, var(--fade));
}

.backgrounded.xbtd22-j:not(:hover) {
    background-color: var(--color-xbtd22-j-faded)
}

.backgrounded.xbtd22-n:not(:hover) {
    background-color: var(--color-xbtd22-n-faded);
}

.wrapper:hover .hover {
    display: block;
}

.canvasWrapper {
    background-color: black;
}

.name {
    color: black;
    font-size: 16px;
    line-height: 36px;;
    font-family: var(--font-sans);
}