.wrapper {
    font-size: 13px;
    line-height: 14px;
    font-family: var(--font-sans);
}

.barWrapper {
    display: flex;
    flex-direction: row;
}

.barWrapper img {
    height: 10px;
    width: 100%;
    margin: 2px 10px 15px;
}