@import "../../css/global.css";

.wrapper {
  padding-top: 5px;
}

.wrapper label {
  width: 75px;
  display: inline-block;
  line-height: 30px;
}

.inputWrapper {
  background-color: black;
  border: 1.5px solid var(--color-border-gray);
  height: 30px;
  border-radius: 30px;
  margin-right: 10px;
  padding: 0 20px;
  display: inline-block
  ;
}

.wrapper input {
  text-align: center;
  color: white;
  background-color: black;
  box-shadow: none;
  border: none;
  width: 30px;
  height: 28px;
}

.wrapper span {
  height: 28px;
  display: inline-block;
}

.rasPositionWrapper,
.ijkPositionWrapper {
  margin: 5px 0;
}

.rasPositionWrapper input {
  width: 50px;
}

.goButton,
.wrapper a {
  text-transform: uppercase;
  color: var(--color-green);
  font-weight: bold;
  font-size: 16px;
}

.goButton:hover,
.wrapper a:hover {
  text-decoration: underline;
  cursor: pointer;
}

.goButton {
  background: none;
	border: none;
	padding: 0;
	cursor: pointer;
	outline: inherit;
}