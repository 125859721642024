.wrapper {
  background: black;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.wrapper > div {
  width: 200px;
}

.wrapper img {
  max-width: 100%;
}

.wrapper a {
  color: white;
  text-decoration: none;
  font-family: var(--font-sans);
}

.wrapper a:hover {
  text-decoration: underline;
} 