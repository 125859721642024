.marker {
  position: absolute;
  pointer-events: none;
}

.cross:before,
.cross:after {
  content: "";
  position: absolute;
  z-index: 2;
  background-color: var(--color-marker-green);
}

.cross:before {
  top: 1.5px;
  left: 50%;
  width: 3px;
  height: 100%;
}

.cross:after {
  left: 1.5px;
  top: 50%;
  height: 3px;
  width: 100%;
}

.circle {
  width: 5px;
  height: 5px;
  border-radius: 50%;
  border-style: solid;
  border-width: 3px;
  border-color: var(--color-marker-green);
}