@import "../../css/global.css";

.wrapper {
  /* margin: 0 auto; */
  position: relative;
}

.infoWrapper {
  min-height: 80px;
  text-align: left;
}

.injectionSiteWrapper {
  margin-top: 15px;
}

.borderImage {
  position: absolute;
  pointer-events: none;
}

.arrows {
  position: absolute;
  right: -20px;
  bottom: 0;
}

.scale {
  position: absolute;
  left: 40px;
  bottom: 0;
}

/* Allows placing the cross on the image */
.imageWrapper {
  position: relative;
  display: inline-block;
  background-position: top left;
  background-repeat: no-repeat;
  margin-bottom: 40px;
}

.cross {
  position: absolute;
  pointer-events: none;
}

.cross:before,
.cross:after {
  content: "";
  position: absolute;
  z-index: 2;
  background-color: var(--color-marker-green);
}

.cross:before {
  top: 1.5px;
  left: 50%;
  width: 3px;
  height: 100%;
}

.cross:after {
  left: 1.5px;
  top: 50%;
  height: 3px;
  width: 100%;
}

.injectionSiteCaption {
    font-family: var(--font-sans);
    font-size: 16px;
}

.injectionSiteCaption label {
    color: var(--color-yellow);
}


